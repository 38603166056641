import React, { useState } from "react";
import { useConfiguration } from "../../context/configuration-context";
import { makeStyles, MenuItem } from "@material-ui/core";
import CotroliaSelect from "./input/CotroliaSelect";
import { useTranslation } from "react-i18next";
import AppConstant from "../../utils/appConstant";
import authService from "../../services/auth.service";
import { useAuth } from "../../context/auth-context";

const useStyles = makeStyles((theme) => ({
  img: {
    width: 15,
    height: 15,
    marginRight: 5,
    marginLeft: 5,
    borderRadius: "50%",
  },
  entry: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const LanguageSelector = ({ userLang = null }) => {
  const { i18n } = useTranslation();
  const { langs, fetchConfiguration } = useConfiguration();
  const { user, updateUser } = useAuth();
  const classes = useStyles();

  const [selectedLang, setSelectedLang] = useState(() => {
    // Manage case when user is connected
    if (userLang) return userLang;

    let langFormated = AppConstant.defaultLanguage;

    switch (i18n.language) {
      case "en": // Format generic english to en_US
        langFormated = "en_US";
        break;
      case "es": // Format generic spanish to es_ES
        langFormated = "es_ES";
        break;
      case "de": // Format generic german to de_DE
        langFormated = "de_DE";
        break;
      case "it": // Format generic italian to it_IT
        langFormated = "it_IT";
        break;
      default:
        if (i18n.language.includes("-")) {
          // Format language code from en-US to en_US
          langFormated = i18n.language.replace("-", "_");
        } else if (i18n.language.includes("_")) {
          langFormated = i18n.language;
        }
        break;
    }

    return langFormated;
  });

  const handleChange = (e) => {
    const newLang = e.target.value;
    i18n.changeLanguage(newLang).then(() => {
      setSelectedLang(newLang);
      if (userLang) {
        // Update user language only if connected
        authService.updateLanguage(newLang);
      }
      fetchConfiguration();
      user.data.language = newLang;
      updateUser(user);
    });
  };

  return (
    
    langs &&
    langs.length > 1 && (
      <CotroliaSelect value={selectedLang} onChange={handleChange}>
        {langs.map((elem) => (
          <MenuItem
            value={elem.code}
            name={elem.code}
            className={classes.entry}
            key={elem.code}
          >
            <img
              src={require(`./../../../public/logo/${elem.code.substring(
                3,
                5
              )}.png`)}
              className={classes.img}
              alt={`${elem.label} flag`}
            ></img>
            {elem.label}
          </MenuItem>
        ))}
      </CotroliaSelect>
    )
  );
};

export default LanguageSelector;
