import moment from "../services/moment";

// Model to represent an empty contact
export const emptyContact = {
  id: null,
  email: "",
  firstname: "",
  lastname: "",
  phone: "",
};

// Model to represent an empty error
export const emptyError = {
  value: false,
  msg: "",
};

// Model to represent an address
export const emptyAddress = {
  street: "",
  zipCode: "",
  city: "",
  country: "",
  countryCode: "",
  fullAddress: "",
  unknown: false,
};

// Model to create intervention
export const interventionModel = {
  id: null,
  status: 0,
  partNbr: 1,
  clientRef: "",
  waranty: 2,
  warrantyPrevInter: null,
  frequency: null,
  description: "",
  refNotVisible: false,
  pictures: [],
  customer_effect: "",
  diagnostic: "",
  parts_replaced: "",
  part: {
    type: null,
    manufacturer: null,
    ref: "",
  },
  car: {
    frame: "",
    carType: 0,
    distance: "",
    registration: "",
    security_code: "",
    year: "",
  },
  shipping: {
    method: "",
    pickupDate: "",
    company: "",
    informations: "",
    address: "",
    phone: "",
    closingDates: {
      monday: false,
      saturday: false,
    },
  },
  mainContact: {
    id: null,
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
  },
  billingContact: {
    id: null,
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
  },
  technicalContact: {
    id: null,
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
  },
};

// fuction to format form values to inter model
export const formateInterventionData = (values, status) => {
  let inter = interventionModel;
  inter.id = values.id !== "" ? values.id : null;
  inter.status = status;
  inter.partNbr = values.packaging.partNb;
  inter.waranty = values.part.waranty;
  inter.warrantyPrevInter = values.part.warrantyPrevInter;
  inter.description =
    values.part.description !== "" ? values.part.description : "Cotrolia";
  inter.clientRef = values.part.clientRef;
  inter.frequency = values.part.frequency !== "" ? values.part.frequency : null;
  inter.refNotVisible = values.part.refNotVisible;
  inter.pictures = values.part.pictures;

  inter.part.type = values.part.type;
  inter.part.manufacturer = values.part.manufacturer;
  inter.part.ref = values.part.ref;

  inter.car.frame = values.car.frame;
  inter.car.carType = values.car.carType;
  inter.car.distance = values.car.distance;
  inter.car.registration = values.car.registration;
  inter.car.security_code = values.car.security_code;
  inter.car.year = moment(values.car.year).format("YYYY");

  inter.customer_effect = values.car.customer_effect
    ? values.car.customer_effect
    : "Cotrolia";
  inter.diagnostic = values.car.diagnostic
    ? values.car.diagnostic
    : "Diagnostic, contrôle de sécurité";
  inter.parts_replaced = values.car.parts_replaced
    ? values.car.parts_replaced
    : "Pièces remplacées et essayées";

  inter.shipping.method = values.shipping.method;
  inter.shipping.pickupDate = moment(values.shipping.pickupDate).format(
    "YYYY-MM-DD hh:mm:ss"
  );
  inter.shipping.company = values.shipping.company;
  inter.shipping.informations = values.shipping.informations;
  inter.shipping.address = values.shipping.address.fullAddress;
  inter.shipping.addressNumber = values.shipping.address.street;
  inter.shipping.zip = values.shipping.address.zipCode;
  inter.shipping.city = values.shipping.address.city;
  inter.shipping.countryCode = values.shipping.address.countryCode;
  inter.shipping.phone = values.shipping.phone;
  inter.shipping.closingDates.monday = values.shipping.closingDates.monday;
  inter.shipping.closingDates.saturday = values.shipping.closingDates.saturday;

  if (values.finish.mainContact) {
    inter.mainContact.id = values.finish.mainContact.id;
    inter.mainContact.email = values.finish.mainContact.email;
    inter.mainContact.firstname = values.finish.mainContact.firstname;
    inter.mainContact.lastname = values.finish.mainContact.lastname;
    inter.mainContact.phone = values.finish.mainContact.phone;
  }

  if (values.finish.billingContact) {
    inter.billingContact.id = values.finish.billingContact.id;
    inter.billingContact.email = values.finish.billingContact.email;
    inter.billingContact.firstname = values.finish.billingContact.firstname;
    inter.billingContact.lastname = values.finish.billingContact.lastname;
    inter.billingContact.phone = values.finish.billingContact.phone;
  }

  if (values.finish.technicalContact) {
    inter.technicalContact.id = values.finish.technicalContact.id;
    inter.technicalContact.email = values.finish.technicalContact.email;
    inter.technicalContact.firstname = values.finish.technicalContact.firstname;
    inter.technicalContact.lastname = values.finish.technicalContact.lastname;
    inter.technicalContact.phone = values.finish.technicalContact.phone;
  }

  return inter;
};
