import { makeStyles, Typography, Button, Divider } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CotroliaStepper, CotroliaStepperActions } from "../core/stepper";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ToggleSection from "../core/ToggleSection";
import StarIcon from "@material-ui/icons/Star";
import GroupIcon from "@material-ui/icons/Group";
import PaymentIcon from "@material-ui/icons/Payment";
import SelectOrAddContact from "../core/SelectOrAddContact";
import AddContactForm from "../core/AddContactForm";
import AddContactEntry from "../core/AddContactEntry";
import { useAuth } from "../../context/auth-context";

const useStyle = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.text.primary,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
  },
  inputBackground: {
    width: "100%",
    borderRadius: theme.spacing(1) + 2,
    backgroundColor: theme.palette.grey[50],
    "& > *": {
      padding: theme.spacing(1.5),
    },
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));

const NewInterventionStepFinish = ({
  values,
  updateValue,
  steps,
  activeStep,
  previousStep,
  handleSend,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { user } = useAuth();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (values.mainContact !== null) {
      if (values.mainContact.id) {
        setMainContact(values.mainContact.id);
      } else {
        setNewMainContact(values.mainContact);
      }
    }

    if (values.billingContact !== null) {
      if (values.billingContact.id) {
        setBillingContact(values.billingContact.id);
      } else {
        setNewBillingContact(values.billingContact);
      }
    }

    if (values.technicalContact !== null) {
      if (values.technicalContact.id) {
        setTechnicalContact(values.technicalContact.id);
      } else {
        setNewTechnicalContact(values.technicalContact);
      }
    }
  }, [values]);

  /* -------------------
   * Main contact values
   * ------------------- */
  const [mainContact, setMainContact] = useState(""); // Select value
  const [displayMainContact, setDisplayMainContact] = useState(false); // Display the add contact form
  const [newMainContact, setNewMainContact] = useState(null); // Set a new contact

  /* ----------------------
   * Billing contact values
   * ---------------------- */
  const [billingContact, setBillingContact] = useState(""); // Select value
  const [displayBillingContact, setDisplayBillingContact] = useState(false); // Display the add contact form
  const [newBillingContact, setNewBillingContact] = useState(null); // Set a new contact

  /* ----------------------
   * Technical contact values
   * ---------------------- */
  const [technicalContact, setTechnicalContact] = useState(""); // Select value
  const [displayTechnicalContact, setDisplayTechnicalContact] = useState(false); // Display the add contact form
  const [newTechnicalContact, setNewTechnicalContact] = useState(null); // Set a new contact

  useEffect(() => {
    const saveLocalValues = () => {
      let contactFormated = null;
      if (newMainContact === null && mainContact !== "") {
        contactFormated = {
          id: mainContact,
          email: "",
          firstname: "",
          lastname: "",
          phone: "",
        };
      } else if (newMainContact !== null) {
        contactFormated = {
          id: "",
          email: newMainContact.email,
          firstname: newMainContact.firstname,
          lastname: newMainContact.lastname,
          phone: newMainContact.phone,
        };
      }
      updateValue("finish", "mainContact", contactFormated);

      contactFormated = null;
      if (newBillingContact === null && billingContact !== "") {
        contactFormated = {
          id: billingContact,
          email: "",
          firstname: "",
          lastname: "",
          phone: "",
        };
      } else if (newBillingContact !== null) {
        contactFormated = {
          id: "",
          email: newBillingContact.email,
          firstname: newBillingContact.firstname,
          lastname: newBillingContact.lastname,
          phone: newBillingContact.phone,
        };
      }
      updateValue("finish", "billingContact", contactFormated);

      contactFormated = null;
      if (newTechnicalContact === null && technicalContact !== "") {
        contactFormated = {
          id: technicalContact,
          email: "",
          firstname: "",
          lastname: "",
          phone: "",
        };
      } else if (newTechnicalContact !== null) {
        contactFormated = {
          id: "",
          email: newTechnicalContact.email,
          firstname: newTechnicalContact.firstname,
          lastname: newTechnicalContact.lastname,
          phone: newTechnicalContact.phone,
        };
      }
      updateValue("finish", "technicalContact", contactFormated);
    };

    saveLocalValues();
  }, [
    mainContact,
    newMainContact,
    billingContact,
    newBillingContact,
    technicalContact,
    newTechnicalContact,
  ]);

  /* ---------------------
   * Main contact handlers
   * --------------------- */
  const handleAddContact = (type, lastname, firstname, email, phone) => {
    const contact = {
      lastname: lastname,
      firstname: firstname,
      email: email,
      phone: phone,
    };
    if (type === "main") {
      setNewMainContact(contact);
      setDisplayMainContact(false);
      setMainContact("");
    } else if (type === "billing") {
      setNewBillingContact(contact);
      setDisplayBillingContact(false);
      setBillingContact("");
    } else {
      setNewTechnicalContact(contact);
      setDisplayTechnicalContact(false);
      setTechnicalContact("");
    }
  };

  const handleEditContact = (type) => {
    if (type === "main") {
      setDisplayMainContact(true);
    } else if (type === "billing") {
      setDisplayBillingContact(true);
    } else {
      setDisplayTechnicalContact(true);
    }
  };

  const handleDeleteContact = (type) => {
    if (type === "main") {
      setNewMainContact(null);
    } else if (type === "billing") {
      setNewBillingContact(null);
    } else {
      setNewTechnicalContact(null);
    }
  };

  const handleCancelContact = (type) => {
    if (type === "main") {
      setDisplayMainContact(false);
    } else if (type === "billing") {
      setDisplayBillingContact(false);
    } else {
      setDisplayTechnicalContact(false);
    }
  };

  const hasErrors = () => {
    let error = false;

    if (mainContact === "" && newMainContact === null) {
      error = true;
    }

    return error;
  };

  const handleClick = () => {
    if (!disabled) { // Check if the button is not already disabled
      setDisabled(true);
    }

    let hasError = false;

    if (mainContact === "" && newMainContact === null) {
      hasError = true;
    }

    if (!hasError) {
      handleSend();
    }
    
  };

  const handleGoBack = () => {
    previousStep();
  };

  return (
    <>
      <Typography variant="h4" className={classes.title} align="center">
        {t("StepFinishTitle")}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.titleDesc}
        align="center"
      >
        {t("StepFinishDesc")}
      </Typography>
      <CotroliaStepper steps={steps} activeStep={activeStep} />

      {/* Main contact*/}
      <ToggleSection
        title={t("MainContact")}
        description={t("MainContactDesc")}
        icon={<StarIcon />}
        defaultExpanded={true}
      >
        {!newMainContact && (
          <SelectOrAddContact
            selected={mainContact}
            onSelect={(value) => {
              setNewMainContact(null);
              setDisplayMainContact(false);
              setMainContact(value);
            }}
            onClick={() => setDisplayMainContact(!displayMainContact)}
            required
            withoutAdd
          />
        )}
        {displayMainContact && (
          <>
            <Divider className={classes.divider} />
            <AddContactForm
              lastname={newMainContact && newMainContact.lastname}
              firstname={newMainContact && newMainContact.firstname}
              email={newMainContact && newMainContact.email}
              phone={newMainContact && newMainContact.phone}
              handleAdd={(lastname, firstname, email, phone) =>
                handleAddContact("main", lastname, firstname, email, phone)
              }
              handleCancel={() => handleCancelContact("main")}
            />
          </>
        )}
        {!displayMainContact && newMainContact && (
          <>
            <Divider className={classes.divider} />
            <AddContactEntry
              contact={newMainContact}
              handleDelete={() => handleDeleteContact("main")}
              handleEdit={() => handleEditContact("main")}
            />
          </>
        )}
      </ToggleSection>

      {/* Billing contact*/}
      {!user.data.individual && (
        <ToggleSection
          title={t("BillingContact")}
          description={t("BillingContactDesc")}
          icon={<PaymentIcon />}
          defaultExpanded={true}
        >
          {!newBillingContact && (
            <SelectOrAddContact
              selected={billingContact}
              onSelect={(value) => {
                setNewBillingContact(null);
                setDisplayBillingContact(false);
                setBillingContact(value);
              }}
              onClick={() => setDisplayBillingContact(!displayBillingContact)}
            />
          )}
          {displayBillingContact && (
            <>
              <Divider className={classes.divider} />
              <AddContactForm
                lastname={newBillingContact && newBillingContact.lastname}
                firstname={newBillingContact && newBillingContact.firstname}
                email={newBillingContact && newBillingContact.email}
                phone={newBillingContact && newBillingContact.phone}
                handleAdd={(lastname, firstname, email, phone) =>
                  handleAddContact("billing", lastname, firstname, email, phone)
                }
                handleCancel={() => handleCancelContact("billing")}
              />
            </>
          )}
          {!displayBillingContact && newBillingContact && (
            <>
              <Divider className={classes.divider} />
              <AddContactEntry
                contact={newBillingContact}
                handleDelete={() => handleDeleteContact("billing")}
                handleEdit={() => handleEditContact("billing")}
              />
            </>
          )}
        </ToggleSection>
      )}

      {/* Technical contact*/}
      {!user.data.individual && (
        <ToggleSection
          title={t("TechnicalContact")}
          description={t("TechnicalContactDesc")}
          icon={<GroupIcon />}
          defaultExpanded={true}
        >
          {!newTechnicalContact && (
            <SelectOrAddContact
              selected={technicalContact}
              onSelect={(value) => {
                setNewTechnicalContact(null);
                setDisplayTechnicalContact(false);
                setTechnicalContact(value);
              }}
              onClick={() =>
                setDisplayTechnicalContact(!displayTechnicalContact)
              }
            />
          )}
          {displayTechnicalContact && (
            <>
              <Divider className={classes.divider} />
              <AddContactForm
                lastname={newTechnicalContact && newTechnicalContact.lastname}
                firstname={newTechnicalContact && newTechnicalContact.firstname}
                email={newTechnicalContact && newTechnicalContact.email}
                phone={newTechnicalContact && newTechnicalContact.phone}
                handleAdd={(lastname, firstname, email, phone) =>
                  handleAddContact(
                    "technical",
                    lastname,
                    firstname,
                    email,
                    phone
                  )
                }
                handleCancel={() => handleCancelContact("technical")}
              />
            </>
          )}
          {!displayTechnicalContact && newTechnicalContact && (
            <>
              <Divider className={classes.divider} />
              <AddContactEntry
                contact={newTechnicalContact}
                handleDelete={() => handleDeleteContact("technical")}
                handleEdit={() => handleEditContact("technical")}
              />
            </>
          )}
        </ToggleSection>
      )}

      <CotroliaStepperActions>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleGoBack}
          disabled={steps[activeStep] === steps[0]} // We disable the back button if we are in the first step
        >
          {t("GoBack")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={handleClick}
          disabled={hasErrors() || disabled}
        >
          {t("SendIntervention")}
        </Button>
      </CotroliaStepperActions>
    </>
  );
};

export default NewInterventionStepFinish;
