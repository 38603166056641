import {
  makeStyles,
  Typography,
  FormHelperText, 
  Button
} from "@material-ui/core";
import CotroliaDatePicker from "../core/input/CotroliaDatePicker";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContentTwoSided, LeftSide, RightSide } from "../core/ContentTwoSided";
import CotroliaFormControl from "../core/input/CotroliaFormControl";
import CotroliaInputLabel from "../core/input/CotroliaInputLabel";
import CotroliaSelect from "../core/input/CotroliaSelect";
import CotroliaTextField from "../core/input/CotroliaTextField";
import { CotroliaStepper, CotroliaStepperActions } from "../core/stepper";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyle = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
  },
  uploadContainer: {
    padding: `${theme.spacing(1.5)}px 0px 0px`,
  },
  mt: {
    marginTop: theme.spacing(2),
  },
}));

const emptyError = {
  error: false,
  msg: "",
};

const NewInterventionStepCar = ({
  values,
  updateValue,
  steps,
  activeStep,
  nextStep,
  previousStep,
  configuration,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  //const [frame, setFrame] = useState(null);
  const [carType, setTypeCar] = useState(null);
  const [typeCarError, setTypeCarError] = useState(emptyError);
  const [frameError, setFrameError] = useState(emptyError);
  const [registrationError, setRegistrationError] = useState(emptyError);
  const [yearError, setYearError] = useState(emptyError);
  const [distanceError, setDistanceError] = useState(emptyError);
  const [securityCodeError, setSecurityCodeError] = useState(emptyError);
  const [customerEffectError, setCustomerEffectError] = useState(emptyError);
  const [diagnosticError, setDiagnosticError] = useState(emptyError);
  const [partsReplacedError, setPartsReplacedError] = useState(emptyError);
  useEffect(() => {
    if (configuration) {
      if (configuration.carType) {
        setTypeCar(configuration.carType);
      }
    }
  }, [configuration]);
  const hasErrors = () => {
    return (
      frameError.error ||
      typeCarError.error ||
      distanceError.error ||
      registrationError.error ||
      securityCodeError.error ||
      yearError.error ||
      customerEffectError.error ||
      diagnosticError.error ||
      partsReplacedError.error
    );
  };

  const handleYearChange = (date) => {
    if (date !== null) {
      setYearError(emptyError);
      updateValue("car", "year", date);
    } else {

      setYearError({
        error: true,
        msg: "RequiredFieldYearError",
      });
    }
  };

  const handleClick = () => {
    let hasError = false;
    
    if (values.car.frame === "") {
      setFrameError({
        error: true,
        msg: "RequiredFieldFrameError",
      });
      hasError = true;
    }

    if (values.car.carType === "") {
      setTypeCarError({
        error: true,
        msg: "RequiredFieldTypeError",
      });
      hasError = true;
    }

    if (values.car.distance === "") {
      setDistanceError({
        error: true,
        msg: "RequiredFieldDistanceError",
      });
      hasError = true;
    }

    if (values.car.registration === "") {
      setRegistrationError({
        error: true,
        msg: "RequiredFieldRegistrationError",
      });
      hasError = true;
    }

    if (values.car.security_code === "") {
      setDistanceError({
        error: true,
        msg: "RequiredFieldSecurityCodeError",
      });
      hasError = true;
    }

    if (values.car.year === "") {
      setYearError({
        error: true,
        msg: "RequiredFieldYearError",
      });
      hasError = true;
    }


    if (values.car.customer_effect === "") {
      setCustomerEffectError({
        error: true,
        msg: "RequiredFieldCustomerEffectError",
      });
      hasError = true;
    }

    if (values.car.diagnostic === "") {
      setDiagnosticError({
        error: true,
        msg: "RequiredFieldDiagnosticError",
      });
      hasError = true;
    }

    if (values.car.parts_replaced === "") {
      setPartsReplacedError({
        error: true,
        msg: "RequiredFieldPartsReplacedError",
      });
      hasError = true;
    }

    if (!hasError) {
      nextStep();
    }
  };
  return (
    
    <>
      
      <Typography variant="h4" className={classes.title} align="center">
        {t("YourBrokenCar")}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.titleDesc}
        align="center"
      >
        {t("YourBrokenCarDesc")}
      </Typography>
      <CotroliaStepper steps={steps} activeStep={activeStep} />

      <ContentTwoSided>
        <LeftSide>
          {/* Frame */}
         <CotroliaFormControl error={frameError.error}>
            <CotroliaTextField
              id="frame"
              label={t("Frame")}
              value={values.car.frame}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setFrameError(emptyError);
                } else {
                  setFrameError({
                    error: true,
                    msg: "RequiredFieldFrameError",
                  });
                }
                updateValue("car", "frame", e.target.value);
              }}
              required
              multiline
              rows={1}
              error={frameError.error}
            />
          </CotroliaFormControl>

          {/* Car type */}
          <CotroliaFormControl
            variant="filled"
            required
            error={typeCarError.error}
          >
            <CotroliaInputLabel id="car-type-label">
              {t("CarType")}
            </CotroliaInputLabel>
            
            <CotroliaSelect
              id="type-car"
              labelId="type-car-label"
              value={values.car.carType}
              native
              onChange={(e) => {
                if (e.target.value !== "") {
                  setTypeCarError(emptyError);
                } else {
                  setTypeCarError({
                    error: true,
                    msg: "RequiredFieldCarTypeError",
                  });
                }
                updateValue("car", "carType", e.target.value);
              }}
            >
              <option value="" />
              {carType &&
                Object.keys(carType).map((key) => (
                  <optgroup label={carType[key].label} key={key}>
                    {carType[key].values &&
                      Object.keys(carType[key].values).map((subkey) => (
                        <option key={subkey} value={subkey}>
                          {carType[key].values[subkey]}
                        </option>
                      ))}
                  </optgroup>
                ))}
            </CotroliaSelect>
            <FormHelperText variant="outlined">
              {t(typeCarError.msg)}
            </FormHelperText>
          </CotroliaFormControl>

          {/* Distance */}
          <CotroliaFormControl error={distanceError.error}>
            <CotroliaTextField
              id="distance"
              label={t("Distance")}
              value={values.car.distance}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setDistanceError(emptyError);
                } else {
                  setDistanceError({
                    error: true,
                    msg: "RequiredFieldDistanceError",
                  });
                }
                updateValue("car", "distance", e.target.value);
              }}
              required
              error={distanceError.error}
            />
          </CotroliaFormControl>

          {/* Registration */}
          <CotroliaFormControl error={registrationError.error}>
            <CotroliaTextField
              id="registration"
              label={t("Registration")}
              value={values.car.registration}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setRegistrationError(emptyError);
                } else {  
                  setRegistrationError({
                    error: true,
                    msg: "RegisterErrorMissingRequiredFields",
                  });
                }
                
                updateValue("car", "registration", e.target.value);
              }}
              required
              error={registrationError.error}
            />
          </CotroliaFormControl>

          {/* Security code */}
          <CotroliaFormControl error={securityCodeError.error}>
            <CotroliaTextField
              id="securitycode"
              label={t("SecurityCode")}
              value={values.car.security_code}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setSecurityCodeError(emptyError);
                } else {
                  setSecurityCodeError({
                    error: true,
                    msg: "RequiredFieldSecurityCodeError",
                  });
                }
                updateValue("car", "security_code", e.target.value);
              }}
              required
              error={distanceError.error}
            />
          </CotroliaFormControl>
          
          {/* Year */}
          <CotroliaFormControl error={yearError.error}>
            <CotroliaDatePicker
                required
                label={t("Year")}
                value={values.car.year}
                format="YYYY"
                onChange={handleYearChange}
                views={["year"]}
                error={yearError.error}
              />       
          </CotroliaFormControl>
        </LeftSide>

        <RightSide>
          {/* Customer Effect */}
          <CotroliaFormControl error={customerEffectError.error}>
            <CotroliaTextField
              id="customer_effect"
              label={t("CarCustomerEffect")}
              value={values.car.customer_effect}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setCustomerEffectError(emptyError);
                } else {
                  setCustomerEffectError({
                    error: true,
                    msg: "RequiredFieldCustomerEffectError",
                  });
                }
                updateValue("car", "customer_effect", e.target.value);
              }}
              required
              multiline
              rows={4}
              error={customerEffectError.error}
            />
          </CotroliaFormControl>

          {/* Diagnostic */}
          <CotroliaFormControl error={diagnosticError.error}>
            <CotroliaTextField
              id="diagnostic"
              label={t("Diagnostic")}
              value={values.car.diagnostic}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setDiagnosticError(emptyError);
                } else {
                  setDiagnosticError({
                    error: true,
                    msg: "RequiredFieldDiagnosticError",
                  });
                }
                updateValue("car", "diagnostic", e.target.value);
              }}
              required
              multiline
              rows={4}
              error={diagnosticError.error}
            />
          </CotroliaFormControl>

          {/* Parts Replaced */}
          <CotroliaFormControl error={partsReplacedError.error}>
            <CotroliaTextField
              id="parts-replaced"
              label={t("PartsReplaced")}
              value={values.car.parts_replaced}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setPartsReplacedError(emptyError);
                } else {
                  setPartsReplacedError({
                    error: true,
                    msg: "RequiredFieldPartsReplacedError",
                  });
                }
                updateValue("car", "parts_replaced", e.target.value);
              }}
              required
              multiline
              rows={4}
              error={partsReplacedError.error}
            />
          </CotroliaFormControl> 
          
        </RightSide>
      </ContentTwoSided>
      
      <CotroliaStepperActions>
        
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            previousStep();
          }}
          
          disabled={steps[activeStep] === steps[0]} // We disable the back button if we are in the first step
        >
          {t("GoBack")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={handleClick}
          disabled={hasErrors()}
        >
          {t("Continue")}
        </Button>
      </CotroliaStepperActions>
    </>
  );
};

export default NewInterventionStepCar;
